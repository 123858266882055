<template>
  <div class="container">
    <b-form-group class="form-group">
      <label for="duration" v-if="label">
        {{ label }}
        <span v-if="!required" class="help">(opcional)</span>
      </label>
      <b-form-input
        class="placeholder-color"
        autocomplete="off"
        id="duration"
        :debounce="debounce || 0"
        :placeholder="placeholder || 'ex: 1 h 30 m'"
        v-model="time"
      />
      <div v-if="option > 0" class="time-label" @click="selectTime(option)">
        {{ parseMinutes(option) }}
      </div>
      <div v-if="error" class="custom-invalid-feedback">{{ error }}</div>
    </b-form-group>
  </div>
</template>
<script>
export default {
  props: {
    value: { validate: v => true },
    debounce: Number,
    error: String,
    required: Boolean,
    label: String,
    placeholder: String,
  },
  data() {
    return {
      time: '',
      option: 0,
    }
  },
  mounted() {
    if (this.value)
      this.time = this.parseMinutes(this.value)
  },
  methods: {
    parseMinutes(minutes) {
      if (minutes >= 60) {
        return `${parseInt(minutes/60)} horas e ${minutes%60} minutos`
      } else {
        return `${minutes} minutos`
      }
    },
    getTime(part) {
      const time = part.replace(/\D/g, '');
      return time ? parseInt(time) : 0;
    },
    selectTime(time) {
      this.time = this.parseMinutes(time);
      this.$emit('input', time);
      this.option = 0;
    }
  },
  watch: {
    time: function(value) {

      if(!value.length) {
        this.$emit('input', 0);
        return;
      }

      const matchHours = value.match(/^\d+\s+h/g);
      const matchMinutes = value.match(/\d+\s+m/g);

      const parts = [matchHours, matchMinutes];
      const option = parts.reduce((acc, part) => {
        if (part && part.length) {
          acc += this.getTime(part[0]) * (part[0].includes('h') ? 60 : 1);
        }
        return acc;
      }, 0);

      if (option !== this.value) {
        this.option = option;
      }
    },
    value: function(value) {
      if(value <= 0) return;
      this.time = this.parseMinutes(value);
    }
  }
}
</script>
<style lang="scss" scoped>
  .container {
    position: relative;
    width: 100%;
    padding: 0;

    .placeholder-color::placeholder {
    color: var(--type-placeholder);
    }

    .form-group {
      margin-bottom: 0 !important;
    }
  }
  .time-label {
    position: absolute;
    width: 100%;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    padding: 0.70rem;
    margin-top: 5px;
    width: max-content;
    cursor: pointer;
    z-index: 100;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);

    &:hover {
      background-color: var(--neutral-100);
    }
  }
</style>
